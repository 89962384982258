.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.blueText {
  color: #59a4df;
}

.brandBlue {
  color: #2a93f4 !important;
}
.lightGraySubtitle {
  font-size: 16px !important;
  color: #807c7c;
}

.graySubtitle {
  font-size: 18px !important;
  font-weight: 300;
  opacity: 0.85;
  color: #807c7c !important;
}

.graySubtitleSmall {
  font-size: 16px !important;
  font-weight: 300;
  opacity: 0.85;
  color: #807c7c !important;
}

.darkGraySubtitle {
  font-size: 20px !important;
  font-weight: 300 !important;
  color: #807c7c;
}

.logoSize {
  font-size: 26px !important;
}

.companyLogo {
  width: 100%;
}

.wideBtn {
  width: 90% !important;
}

.roundedBtn {
  border-radius: 7px !important;
}

.wideBtn100 {
  width: 100% !important;
}

.blueBackground {
  background-color: #2a93f4 !important;
  height: 120vh !important;
}

.height100 {
  height: 100vh;
}

.blueCard {
  background-color: #2a73f4;
  color: white;
}

.blueButton {
  background-color: #2a73f4 !important;
  color: white !important;
}

.blueCard h3 {
  color: white;
}
.subheader {
  font-size: 17px;
}
.subheaderGray {
  font-size: 17px;
  color: rgb(110, 110, 110);
}

.blue-btn {
  border-radius: 5px;
  background-color: #2e6cb9 !important;
  color: white !important;
  border-width: 0 !important;
  font-size: 22px !important;
  line-height: 50px !important;
  height: 50px !important;
  width: 150px;
}

.turquoise-btn {
  border-radius: 5px;
  background-color: #2bcfbc !important;
  color: white !important;
  border-width: 0 !important;
  font-size: 22px !important;
  line-height: 50px !important;
  height: 50px !important;
  width: 150px;
}

.navbarWhite {
  background-color: white !important;
}

.pending {
  color: #2a93f4;
}

.complete {
  color: #2bcfbc;
}

img.twemoji {
  height: 2.5em;
  width: 2.5em;
  margin: 0 0.05em 0 0.1em;
  vertical-align: -0.1em;
}

.alignCenter {
  text-align: center;
}

.minWidth {
  min-width: 1000px;
}

.minWidth500 {
  min-width: 500px;
}

.noBorder {
  border-width: 0px !important;
  box-shadow: none !important;
  padding: 0px !important;
  margin: 0px !important;
}

.halfHeight {
  height: 50% !important;
}

.borderBottom {
  border-bottom: 1px !important;
  border-color: black !important;
}

.smallPrint {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* or 150% */
  letter-spacing: -0.24px;
  color: #807c7c !important;
  /* Grey 2 */
}

.smallPrint-link {
  color: #535c88 !important;
  height: auto;
}

.withdrawalAmount {
  border: 0px !important;
  font-size: 26px !important;
  border-bottom: 1px solid #807c7c !important;
}

.withdrawalAmount:focus {
  outline: none;
}

.withdrawalAmount[type="text"] {
  font-size: 40px !important;
}
.poundSign {
  font-size: 26px;
}

/* Figma Styles */

/* Modal Style */

.popup-modal {
  width: 476px !important;
  min-width: 330px;
  background: #fffdfc !important;
  padding-bottom: 0px !important;
  padding: 0 0 0 0 !important;
  border-radius: 16px !important;
}

.ant-modal-content {
  border-radius: 16px !important;
}

.modal-wrapper {
  padding: 24px 22px 24px 22px !important;

  height: 100%;
  width: 380px;
  margin: 0 auto;
}

.modal-header {
  padding: 0 0 0 0 !important;
  border-bottom: 0px !important;
  margin-bottom: 4px;
}

.modal-header h4 {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 0px;
}

.modal-subheader {
  padding: 0 0 0 0 !important;
}

.modal-subheader p {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.24px;

  color: #807c7c;
}

.modal-body {
  max-width: 380px;
  background: #ffffff;
  box-shadow: 0px 0px 32px #fbf2ec;
  border-radius: 16px;
  padding: 0px !important;
}

.modal-body-header {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 400px) {
  /* For mobile phones: */
  .modal-body {
    width: 100% !important;
    align-self: center;
    margin-right: 0 !important;
  }

  .modal-wrapper {
    width: 100%;
  }
  .liquid-primary {
    width: 240px !important;
    align-self: center;
  }

  .navbar-slogan {
    min-width: 130px !important;
  }

  .liquid-navbar-brand .navbar-slogan p {
    font-size: 50px !important;
  }
}

@media only screen and (max-width: 480px) {
  .liquid-navbar-brand {
    height: 1px;
    width: 1px;
    position: absolute;
    overflow: hidden;
    top: -10px;
  }
}

@media only screen and (max-width: 650px) {
  .navbar-slogan {
    height: 1px;
    width: 1px;
    position: absolute;
    overflow: hidden;
    top: -10px;
  }
}

@media only screen and (min-width: 480px) {
  .liquid-navbar-brand-small {
    height: 1px;
    width: 1px;
    position: absolute;
    overflow: hidden;
    top: -10px;
  }
}

.login-link {
  margin-right: 50px;
  margin-top: 19px;
}

.login-link h6 {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height, or 150% */
  text-align: right;
  letter-spacing: -0.24px;

  color: #535c88;
}

.modal-body-wrapper {
  padding: 24px;
  margin-bottom: 32px;
}
.modal-footer {
  margin-bottom: 64px;
}
.modal-body-wrapper h1 {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  /* identical to box height, or 156% */
  letter-spacing: -0.24px;

  color: #2b2a29;
}

.modal-body-wrapper h4 {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height, or 150% */
  letter-spacing: -0.24px;

  color: #535c88;
}

.modal-body-wrapper p {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  /* or 156% */
  letter-spacing: -0.24px;

  color: #807c7c;
}

.modal-body input {
  width: 100%;
  border: 0px !important;
  height: 34px;
}

.modal-body input:focus {
  outline: none;
}

.modal-body input[type="text"] {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  color: #000;
}

.modal-body input::placeholder {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  /* identical to box height, or 121% */

  color: #e0e0e0;
}

.modal-body h2 {
  /* nBody Regular 14 */
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* or 150% */
  letter-spacing: -0.24px;

  /* Grey 2 */
  color: #807c7c;
}

.liquid-primary {
  border-style: none;
  height: 40px;
  background: #535c88;
  border-radius: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.liquid-primary p {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  /* or 156% */
  display: flex;
  align-items: center !important;
  justify-content: center;
  text-align: center;
  letter-spacing: -0.24px;
  margin-bottom: 0px;
  color: #ffffff;
}

/* Nav Bar */

.liquid-navbar-brand {
  padding: 0 0 0 0 !important;
  display: flex;
}

.liquid-navbar-brand p {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  /* identical to box height, or 156% */
  display: flex;
  align-items: center;
  letter-spacing: -0.24px;

  color: #b8c2c7;
}

.navbar-slogan {
  margin-left: 24px;
  margin-top: 21px;
}

.nav-link {
  padding: 0 0 0 0 !important;
}

.navbar-logo {
  margin-left: 50px;
  height: 24px;
  margin-top: 24px;
  padding: 0 0 0 0 !important;
}

.navbar-logo-small {
  margin-top: 20px;
  margin-left: 24px;
}

.liquid-nav-bar {
  height: 64px;
  background: #fff;
  display: flex;
  justify-content: space-between;
}

.nav-bar-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 50px;
}

.emoji-clap {
  font-size: 22px;
  margin-right: 8px;
  margin-bottom: 15px !important;
}

.main {
  background: #fef9f7 !important;
  min-height: 100vh !important;
}

/* Employee Dashboard */
.employee-dashboard {
  margin-top: 34px;
  max-width: 780px;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 0px 0px 0px !important;
}

.dashboard-header h1 {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
}

.dashboard-header h5 {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  /* or 156% */
  display: flex;
  align-items: center;
  letter-spacing: -0.24px;

  /* Black */
  color: #000000;
}

.earned-panel {
  width: 380px;
  height: 103px;
  background: #ffffff;
  box-shadow: 0px 0px 32px #fbf2ec;
  border-radius: 16px;
  padding: 20px 20px 24px 24px;
  margin-bottom: 12px;
}

.earned-panel p {
  /* nBody Regular 14 */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */
  letter-spacing: -0.24px;

  color: #807c7c;
  margin-bottom: 8px;
}

.earned-panel h3 {
  font-family: "Source Sans Pro";
  font-size: 28px;
  line-height: 34px;
  /* identical to box height, or 121% */

  color: #535c88;
}

.withdraw-panel {
  width: 380px;
  height: 103px;
  background: #535c88;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 20px 20px 24px 24px;
  margin-bottom: 12px;
}

.withdraw-panel p {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */
  letter-spacing: -0.24px;

  color: #ffffff;
  margin-bottom: 8px;
}

.withdraw-panel h3 {
  font-family: "Source Sans Pro";
  font-size: 28px;
  line-height: 34px;
  /* identical to box height, or 121% */

  /* White */
  color: #ffffff;
}

.withdraw-panel button {
  background: #ec7753;
  border-radius: 8px;
  width: 127px;
  height: 40px;
  border-style: none;
}

.withdraw-panel button p {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  /* or 156% */
  display: flex;
  align-items: center !important;
  justify-content: center;
  text-align: center;
  letter-spacing: -0.24px;
  margin-bottom: 0px;
  color: #ffffff;
}

/* Withdrawals Table */

.table-header {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */
  letter-spacing: -0.24px;

  color: #807c7c;
}

.table-header thead {
  background-color: #fdf9f6 !important;
}

.withdrawal-table-row {
  background-color: #fdf9f6 !important;
}

.withdrawal-table {
  padding: 24px 24px 24px 24px;
}

.withdrawal-table h1 {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  /* identical to box height, or 121% */

  color: #000000;
}

.popover {
  border-style: none !important;
  top: -2px;
  padding: 20px 20px 20px 20px;
  width: 256px;
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  /* nBody Regular 14 */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* or 150% */
  letter-spacing: -0.24px;

  color: #000000;
}

/* Confirm Modal */

.confirm-modal-wrapper {
  height: 100%;
  width: 380px;
  margin-top: 92px;
  margin-bottom: 112px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.confirm-modal-wrapper input {
  width: 150px !important;
  border-style: none;
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  /* identical to box height, or 121% */
  text-align: center;
  color: #000000;
}

.confirm-modal-wrapper input:focus {
  outline: none !important;
}

.confirm-modal-wrapper input::placeholder {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 33px;
  line-height: 34px;
  /* identical to box height, or 121% */
  text-align: center;
  color: #b8c2c7;
}

.modal-action-call {
  margin-top: 64px;
  width: 316px;
  margin-left: auto;
  margin-right: auto;
}

.confirm-modal-header h4 {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  /* or 121% */
  text-align: center;

  color: #000000;
}

.confirm-modal-header p {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  /* or 150% */
  text-align: center;
  letter-spacing: -0.41px;

  /* Grey 2 */
  color: #807c7c;
}

.text-button {
  border-style: none;
  margin-top: 32px;
  background: transparent;
  text-align: center;
  width: 200px;
  color: #535c88;
}

.text-button p {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* or 150% */
  display: flex;
  align-items: center !important;
  text-align: center;
  letter-spacing: -0.24px;
}

.text-button:focus {
  outline: none;
}

.edit-bank-acc p {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* or 150% */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.24px;

  color: #535c88;
}

/* Congrats Modal */

.congrats-modal {
  width: 416px;

  background: #fffdfc;
  padding: 0 0 0 0 !important;
}

.congrats-modal-wrapper {
  padding: 48px 44px 48px 44px !important;
  height: 450px;
}

.congrats-modal-header {
  margin-left: 24px !important;
}

.congrats-modal-header h1 {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  /* identical to box height, or 121% */
  color: #000000;
  margin-bottom: 0px !important;
}

.congrats-modal-header span {
  margin-bottom: 0px;
}

.congrats-modal-header h3 {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  /* identical to box height, or 127% */
  letter-spacing: -0.24px;

  color: #504d4d;
}

/* Account Not Ready Modal */
.not-ready-modal-wrapper {
  padding: 24px 22px 24px 22px !important;
}

.not-ready-modal-wrapper h4 {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  /* identical to box height, or 150% */
  letter-spacing: -0.24px;

  color: #535c88;
}

/* Info Modal */

.info-modal {
  width: 476px;
  padding: 0 0 0 0;
  background: #ffffff;
  border-radius: 8px !important;
}

.info-modal-wrapper {
  padding: 24px 24px 24px 24px;
  border-radius: 8px !important;
}

.info-modal h1 {
  font-family: "Montserrat-SemiBold";
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  /* identical to box height, or 121% */

  color: #000000;
}

.info-modal h6 {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 25px;
  /* or 156% */
  letter-spacing: -0.24px;
  margin-bottom: 2px;
  color: #000000;
}

.info-modal p {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-size: 16px;
  line-height: 25px;
  /* or 156% */
  letter-spacing: -0.24px;

  color: #000000;
}

/* Signup Screen */

.signup-form {
  margin-top: 64px;
  width: 580px;
  margin-left: auto;
  margin-right: auto;
  background-color: #ffffff !important;
  box-shadow: 0px 0px 64px #fbf2ec;
  border-radius: 16px;
}

.signup-wrapper {
  padding: 64px 132px 64px 132px;
  margin-bottom: 200px;
}

.signup-form h3 {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  /* identical to box height, or 121% */
  margin-bottom: 0px;
  color: #000000;
}

.signup-form input {
  margin-top: 8px;
  margin-bottom: 0px !important;
  width: 316px;
  height: 64px;
  border: none;
  border-bottom: solid !important;
  border-width: 1px !important;
  border-color: #b8c2c7 !important;

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  /* or 156% */
  letter-spacing: -0.24px;
}

.signup-form input::placeholder {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  /* or 156% */
  letter-spacing: -0.24px;

  color: #807c7c;
}

.signup-form input:focus {
  outline: none !important;
  border-width: 2px !important;
  border-color: #535c88 !important;
}

.signup-checkbox {
  height: 16px;
}

.signup-form-button {
  border-style: none;
  height: 40px;
  background: #535c88;
  border-radius: 8px;
  width: 100%;
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup-form-button p {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  /* or 156% */
  display: flex;
  align-items: center !important;
  justify-content: center;
  text-align: center;
  letter-spacing: -0.24px;
  margin-bottom: 0px;
  color: #ffffff;
}

.phone-confirm-modal {
  width: 580px;
  margin-top: 128px;
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
  box-shadow: 0px 0px 64px #fbf2ec;
  border-radius: 16px;
}

.phone-confirm-modal h1 {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  /* identical to box height, or 121% */

  color: #000000;
}

.phone-confirm-modal-wrapper {
  padding-bottom: 64px;
  padding-top: 64px;
  height: 100%;
  width: 492px;
  margin-top: 92px !important;
  margin-bottom: 112px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.phone-confirm-modal-wrapper input {
  width: 150px !important;

  border-style: none;
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  /* identical to box height, or 121% */
  text-align: center;
  color: #000000;
}

.phone-confirm-modal-wrapper input:focus {
  outline: none !important;
}

.phone-confirm-modal-wrapper input::placeholder {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 39px;
  line-height: 38px;
  /* identical to box height, or 121% */
  text-align: center;
  color: #b8c2c7;
}

/* Brand Colours */

.brand-bg-light {
  background-color: #fef9f7 !important;
  height: 1100px;
}

.checkbox-wrapper {
  display: flex;
  margin-top: 18px;
}

.checkbox-wrapper input:checked ~ .checkmark {
  background-color: #535c88 !important;
}

.small-checkbox {
  width: 16px !important;
  height: 16px !important;
  margin-right: 16px;
  margin: 0px !important;
  display: block;
  background: #535c88 !important;
  border-radius: 6px !important;
  margin-top: 2px !important;
}

.small-checkbox :checked {
  background-color: #535c88 !important;
}

.smallprint-wrapper {
  width: 284px !important;
  display: block;
  margin-left: 16px;
}
.smallprint-wrapper p {
  width: 284px !important;
  display: block;
  /* nBody Regular 14 */
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 21px;
  /* or 150% */
  letter-spacing: -0.24px;

  /* Grey 2 */
  color: #807c7c;
}

/* Dashboard Salary Advances Panel */
.dashboard-panel {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

/* Input Search Element */
.search-result-item {
  background-color: #fff;
  cursor: pointer;
  width: 316px;
  border-style: solid;
  border-top-style: none !important;
  border-width: 1px;
}

.search-result-item :hover {
  background-color: #e0e0e0;
}

.search-result-item p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 0px !important;

  padding: 15px 10px 15px 10px;
}
